import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = Component => props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                { path: 'login-unprotected', element: <Login /> },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
                { path: 'new-password', element: <NewPassword /> },
                { path: 'verify', element: <VerifyCode /> },
            ],
        },

        // Dashboard Routes
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                { path: 'app', element: <DashboardMain /> },
                { path: 'hierarchy', element: <DashboardHierarchy /> },
                {
                    path: 'admin',
                    children: [
                        { element: <Navigate to="/dashboard/admin/settings" replace />, index: true },
                        { path: 'settings', element: <AdminSettings /> },
                    ],
                },
                {
                    path: 'tenant',
                    children: [
                        { element: <Navigate to="/dashboard/tenant" replace />, index: true },
                        { path: 'new', element: <TenantCreate /> },
                        { path: 'list', element: <TenantManager /> },
                        { path: ':id/edit', element: <TenantCreate /> },
                    ],
                },
                {
                    path: 'Roles',
                    children: [
                        { element: <Navigate to="/dashboard/roles" replace />, index: true },
                        { path: 'manage', element: <RolesManager /> },
                    ],
                },
                {
                    path: 'relationship',
                    children: [
                        { element: <Navigate to="/dashboard/relationship" replace />, index: true },
                        { path: 'manage', element: <RelationshipManager /> },
                    ],
                },
                {
                    path: 'patient',
                    children: [
                        { element: <Navigate to="/dashboard/patient" replace />, index: true },
                        { path: 'new', element: <PatientCreate /> },
                        { path: 'easyfill', element: <PatientEasyFill /> },
                        { path: 'list', element: <PatientManager /> },
                        { path: ':id/:tenantid/edit', element: <PatientCreate /> },
                    ],
                },
                {
                    path: 'insurance',
                    children: [
                        { element: <Navigate to="/dashboard/insurance" replace />, index: true },
                        { path: 'new', element: <InsuranceCreate /> },
                        { path: 'list', element: <InsuranceManager /> },
                        { path: ':id/edit', element: <InsuranceCreate /> },
                    ],
                },
                {
                    path: 'device',
                    children: [
                        { element: <Navigate to="/dashboard/device" replace />, index: true },
                        { path: 'new', element: <DeviceCreate /> },
                        { path: 'list', element: <DeviceManager /> },
                        { path: ':id/edit', element: <DeviceCreate /> },
                    ],
                },
                {
                    path: 'billing',
                    children: [
                        { element: <Navigate to="/dashboard/billing" replace />, index: true },
                        { path: 'manage', element: <BillingManager /> },
                    ],
                },
                {
                    path: 'settings',
                    children: [
                        { element: <Navigate to="/dashboard/settings" replace />, index: true },
                        { path: 'readings', element: <SettingsReadings /> },
                    ],
                },
                {
                    path: 'superadmin',
                    children: [
                        { element: <Navigate to="/dashboard/superadmin" replace />, index: true },
                        { path: 'manage', element: <SuperAdmin /> },
                    ],
                },
                { path: 'permission-denied', element: <PermissionDenied /> },
            ],
        },

        // Main Routes
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'coming-soon', element: <ComingSoon /> },
                { path: 'maintenance', element: <Maintenance /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <Page404 /> },
                { path: '403', element: <Page403 /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        {
            path: '/',
            element: <Login />,
            // children: [
            //     { element: <HomePage />, index: true },
            // ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// GENERAL
const DashboardMain = Loadable(lazy(() => import('../pages/dashboard/DashboardMain')));
const DashboardHierarchy = Loadable(lazy(() => import('../pages/dashboard/DashboardHierarchy')));
const AdminSettings = Loadable(lazy(() => import('../pages/dashboard/AdminSettings')));

// TENANT
const TenantManager = Loadable(lazy(() => import('../pages/dashboard/TenantManager')));
const TenantCreate = Loadable(lazy(() => import('../pages/dashboard/TenantCreate')));

// USERS
const RelationshipManager = Loadable(lazy(() => import('../pages/dashboard/RelationshipManager')));

// PATIENT
const PatientCreate = Loadable(lazy(() => import('../pages/dashboard/PatientCreate')));
const PatientManager = Loadable(lazy(() => import('../pages/dashboard/PatientManager')));
const PatientEasyFill = Loadable(lazy(() => import('../pages/dashboard/PatientEasyFill')));

// Roles
const RolesManager = Loadable(lazy(() => import('../pages/dashboard/RolesManager')));

// Settings
const SettingsReadings = Loadable(lazy(() => import('../pages/dashboard/SettingsReadings')));

// DEVICE
const DeviceCreate = Loadable(lazy(() => import('../pages/dashboard/DeviceCreate')));
const DeviceManager = Loadable(lazy(() => import('../pages/dashboard/DeviceManager')));

// DEVICE
const InsuranceCreate = Loadable(lazy(() => import('../pages/dashboard/InsuranceCreate')));
const InsuranceManager = Loadable(lazy(() => import('../pages/dashboard/InsuranceManager')));

// Notes
const SuperAdmin = Loadable(lazy(() => import('../pages/dashboard/SuperAdmin')));

// Billing
const BillingManager = Loadable(lazy(() => import('../pages/dashboard/BillingManager')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionsDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

