// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

/********* CRPM APIs *********/

// Set API service names here
const apiNames = [
    'tenant', 
    'user', 
    'patient', 
    'device', 
    'insurance', 
    'healthlake', 
    'observation', 
    'crpm'
];

const createEndpoint = name => {
    let apiLink = '';

    switch (process.env.REACT_APP_STAGE) {
        case 'devsoor':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        case 'demo':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        case 'prod':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        default:
            apiLink = process.env.REACT_APP_API_URL;
            break;
    }
    const item = {
        name: name,
        endpoint: apiLink,
        region: process.env.REACT_APP_REGION,
    };
    return item;
};

const endpoints = [];
apiNames.forEach(item => {
    const ep = createEndpoint(item);
    endpoints.push(ep);
});

export const AwsConfig = {
    s3: {
        REGION: process.env.REACT_APP_REGION,
        BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
        endpoints: endpoints,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
};

export const roleNames = [
    {
        role: 'physician',
        level: 1,
        title: 'Physician',
        bgcolor: 'info.lighter',
        icon: <PermIdentityOutlinedIcon />,
        reportsTo: [],
    },
    {
        role: 'nursemanager',
        level: 2,
        title: 'Nurse Manager',
        bgcolor: 'primary.lighter',
        icon: <ManageAccountsOutlinedIcon />,
        reportsTo: ['physician'],
    },
    {
        role: 'nurse',
        level: 3,
        title: 'Nurse',
        bgcolor: 'warning.lighter',
        icon: <EmojiEmotionsOutlinedIcon />,
        reportsTo: ['physician', 'nursemanager'],
    },
    {
        role: 'medicalassistant',
        level: 4,
        title: 'Medical Assistant',
        bgcolor: 'error.lighter',
        icon: <Person2OutlinedIcon />,
        reportsTo: ['physician', 'nursemanager', 'nurse'],
    },
];

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 32,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'default',
    themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: '/assets/icons/flags/ic_flag_fr.svg',
    },
    {
        label: 'Vietnamese',
        value: 'vn',
        systemValue: viVN,
        icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
    {
        label: 'Chinese',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
        label: 'Arabic (Sudan)',
        value: 'ar',
        systemValue: arSD,
        icon: '/assets/icons/flags/ic_flag_sa.svg',
    },
];

export const defaultLang = allLangs[0]; // English
