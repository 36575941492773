// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import MonitorTwoToneIcon from '@mui/icons-material/MonitorTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// ----------------------------------------------------------------------

const getIcon = name => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'Dashboards',
        items: [
            { title: 'Summary', path: PATH_DASHBOARD.general.app, icon: ICONS.analytics },
            // { title: 'Org Structure', path: PATH_DASHBOARD.general.hierarchy, icon: <AccountTreeTwoToneIcon/> },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'management',
        items: [
            {
                title: 'Organization',
                path: PATH_DASHBOARD.tenant.list,
                icon: <GroupsTwoToneIcon />,
            },
            {
                title: 'Org Structure',
                path: PATH_DASHBOARD.relationship.manage,
                icon: <AccountTreeTwoToneIcon />,
            },
            {
                title: 'Devices',
                path: PATH_DASHBOARD.device.list,
                icon: <MonitorTwoToneIcon />,
            },
            {
                title: 'Insurance',
                path: PATH_DASHBOARD.insurance.list,
                icon: <ReceiptTwoToneIcon />,
            },
            {
                title: 'Patient',
                path: PATH_DASHBOARD.patient.list,
                icon: <SupervisedUserCircleTwoToneIcon />,
                // children: [
                //     { title: 'Profiles', path: PATH_DASHBOARD.patient.list },
                //     { title: 'readings', path: PATH_DASHBOARD.patient.readings },
                // ],
            },
            // {
            //     title: 'Notes',
            //     path: PATH_DASHBOARD.notes.manage,
            //     icon: <NoteTwoToneIcon />,
            // },
            {
                title: 'Settings',
                path: PATH_DASHBOARD.settings.readings,
                icon: <SettingsTwoToneIcon />,
            },
            // {
            //     title: 'Administration',
            //     path: PATH_DASHBOARD.superadmin.manage,
            //     icon: <AdminPanelSettingsIcon />,
            // },
        ],
    },
];

export default navConfig;
