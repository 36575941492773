import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer } from 'react';

// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
//
import { Auth } from 'aws-amplify';

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    AUTHENTICATE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGOUT: state => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    registerConfirm: () => Promise.resolve(),
    resendCode: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    resetPasswordSubmit: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node,
};

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getSession = useCallback(
        () =>
            new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        if (user) {
                            Auth.currentSession()
                                .then(session => {
                                    dispatch({
                                        type: 'AUTHENTICATE',
                                        payload: { isAuthenticated: true, user: user.attributes },
                                    });
                                    resolve({
                                        user,
                                        session,
                                    });
                                })
                                .catch(err => console.log(err));
                        } else {
                            dispatch({
                                type: 'AUTHENTICATE',
                                payload: {
                                    isAuthenticated: false,
                                    user: null,
                                },
                            });
                        }
                    })
                    .catch(err => console.log(err));
            }),
        []
    );

    const initial = useCallback(async () => {
        try {
            await getSession();
        } catch {
            dispatch({
                type: 'AUTHENTICATE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    }, [getSession]);

    useEffect(() => {
        initial();
    }, [initial]);

    const login = async (email, password, role) => {
        try {
            const user = await Auth.signIn(email, password);
            if (user) {
                getSession();
                window.location.href = PATH_DASHBOARD.root;
            }
        } catch (error) {
            return error;
        }
    };

    const logout = async () => {
        await Auth.signOut();
        window.localStorage.clear();
        dispatch({ type: 'LOGOUT' });
    };

    const register = async (email, password, firstName, lastName, tenantID, role) => {
        try {
            await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    given_name: firstName,
                    family_name: lastName,
                    // 'custom:role': role,
                    // 'custom:tenantID': tenantID,
                },
            });

            window.location.href = PATH_AUTH.verify + '?email=' + email + '?role=' + role;
        } catch (err) {
            console.log('register: ', err);
            return err;
        }
    };

    const registerConfirm = async (email, confirmationCode) => {
        try {
            const response = await Auth.confirmSignUp(email, confirmationCode);
            if (response.status !== 'SUCCESS') {
                return response;
            }
        } catch (err) {
            console.log(err.message);
            return err;
        }
    };

    const resendCode = async email => {
        try {
            await Auth.resendSignUp(email);
        } catch (err) {
            console.log(err);
        }
    };
    const forgotPassword = async email => {
        try {
            await Auth.forgotPassword(email);
        } catch (err) {
            console.log(err);
        }
    };
    const resetPasswordSubmit = async (email, authCode, password) => {
        try {
            const response = await Auth.forgotPasswordSubmit(email, authCode, password);
            if (response !== 'SUCCESS') {
                return response;
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                user: {
                    displayName: state?.user?.given_name,
                    ...state.user,
                },
                login,
                register,
                registerConfirm,
                resendCode,
                forgotPassword,
                resetPasswordSubmit,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
