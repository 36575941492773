// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
    home: '/',
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    about: '/about-us',
    contact: 'contact',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
        hierarchy: path(ROOTS_DASHBOARD, '/hierarchy'),
    },
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
    admin: {
        root: path(ROOTS_DASHBOARD, '/admin'),
        settings: path(ROOTS_DASHBOARD, '/admin/settings'),
    },
    tenant: {
        root: path(ROOTS_DASHBOARD, '/tenant'),
        list: path(ROOTS_DASHBOARD, '/tenant/list'),
        new: path(ROOTS_DASHBOARD, '/tenant/new'),
        edit: id => path(ROOTS_DASHBOARD, `/tenant/${id}/edit`),
    },
    relationship: {
        root: path(ROOTS_DASHBOARD, '/relationship'),
        manage: path(ROOTS_DASHBOARD, '/relationship/manage'),
    },
    physician: {
        root: path(ROOTS_DASHBOARD, '/physician'),
        list: path(ROOTS_DASHBOARD, '/physician/list'),
        new: path(ROOTS_DASHBOARD, '/physician/new'),
        edit: (id, role) => path(ROOTS_DASHBOARD, `/physician/${id}/${role}/edit`),
    },
    nursemanager: {
        root: path(ROOTS_DASHBOARD, '/nursemanager'),
        list: path(ROOTS_DASHBOARD, '/nursemanager/list'),
        new: path(ROOTS_DASHBOARD, '/nursemanager/new'),
        edit: (id, role) => path(ROOTS_DASHBOARD, `/nursemanager/${id}/${role}/edit`),
    },
    nurse: {
        root: path(ROOTS_DASHBOARD, '/nurse'),
        list: path(ROOTS_DASHBOARD, '/nurse/list'),
        new: path(ROOTS_DASHBOARD, '/nurse/new'),
        edit: (id, role) => path(ROOTS_DASHBOARD, `/nurse/${id}/${role}/edit`),
    },
    medicalassistant: {
        root: path(ROOTS_DASHBOARD, '/medicalassistant'),
        list: path(ROOTS_DASHBOARD, '/medicalassistant/list'),
        new: path(ROOTS_DASHBOARD, '/medicalassistant/new'),
        edit: (id, role) => path(ROOTS_DASHBOARD, `/medicalassistant/${id}/${role}/edit`),
    },
    patient: {
        root: path(ROOTS_DASHBOARD, '/patient'),
        list: path(ROOTS_DASHBOARD, '/patient/list'),
        new: path(ROOTS_DASHBOARD, '/patient/new'),
        easyfill: path(ROOTS_DASHBOARD, '/patient/easyfill'),
        edit: (id, tenantid) => path(ROOTS_DASHBOARD, `/patient/${id}/${tenantid}/edit`),
    },
    device: {
        root: path(ROOTS_DASHBOARD, '/device'),
        list: path(ROOTS_DASHBOARD, '/device/list'),
        new: path(ROOTS_DASHBOARD, '/device/new'),
        edit: id => path(ROOTS_DASHBOARD, `/device/${id}/edit`),
    },
    insurance: {
        root: path(ROOTS_DASHBOARD, '/insurance'),
        list: path(ROOTS_DASHBOARD, '/insurance/list'),
        new: path(ROOTS_DASHBOARD, '/insurance/new'),
        edit: id => path(ROOTS_DASHBOARD, `/insurance/${id}/edit`),
    },
    task: {
        root: path(ROOTS_DASHBOARD, '/task'),
        manage: path(ROOTS_DASHBOARD, '/task/manage'),
    },
    billing: {
        root: path(ROOTS_DASHBOARD, '/billing'),
        manage: path(ROOTS_DASHBOARD, '/billing/manage'),
    },
    settings: {
        root: path(ROOTS_DASHBOARD, '/settings'),
        readings: path(ROOTS_DASHBOARD, '/settings/readings'),
    },
    superadmin: {
        root: path(ROOTS_DASHBOARD, '/superadmin'),
        manage: path(ROOTS_DASHBOARD, '/superadmin/manage'),
    },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
