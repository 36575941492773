// ----------------------------------------------------------------------

export default function Menu(theme) {
    return {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            },
        },
    };
}
